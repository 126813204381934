#menumagazineresponsiveM{
    display: none;
}
html {
    overflow-x: hidden;
   }


@media screen and (max-width : 575px) { 
    #menumagazineresponsiveM{
        display: block;
    }
    html {
        overflow-x: hidden;
       }
}