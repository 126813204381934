@font-face{
    font-family: Athelas;
    src: url("fonts/Athelas.ttc");
  }




.img-sup-video{
    background-image: url("imagenes/imgfondo.png");
}

.cont-img-video{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 10%;
    padding-top: 1%;
    padding-bottom: 1%;
}

#menuresposivenotesV{
    display: none;
}

.box-text-principal{
    display: flex;
justify-content: center;
padding-top: 1%;
}

.text-video-principal{
    color: rgb(147, 4, 213);
    font-size: 180%;
    font-family: "Athelas";
}
.video-principal{
    padding-bottom: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-videos-secciones{
    width: 100%;
    border-top: 1px solid rgb(147, 4, 213);
   
    padding-top: 1%;
    padding-bottom: 1%;
}

.box-text-sugerencia{
    display: flex;
    justify-content: center;
    padding-bottom: 2%;
}

.text-sugerencias{
    color: rgb(147, 4, 213);
    font-size: 180%;
    font-family: "Athelas";
}
.video1 .video2 .video3 .video4 .video5{
width: 100%;
padding-left: 5%;
padding-right: 5%;
}


.box-text-list{
display: flex;
justify-content: center;
}

.text-list-videos{
    color: rgb(147, 4, 213);
    font-size: 180%;
    font-family: "Athelas";
}
.container-videos{
    width: 100%;
}
@media screen and (max-width : 575px) {

    .box-text-principal {
        display: flex;
        justify-content: center;
        padding-top: 7%;
    }

    html {
        overflow-x: hidden;
       }

       .text-sugerencias{
        color: rgb(147, 4, 213);
        font-size: 105%;
        text-align: center;
        font-family: "Athelas";
    }

#menuresposivenotesV{
    display: block;
}
  #menuizquierdav{
    padding: 15% 173% 0% 55%;
    border-right: 1px solid rgb(187, 181, 181);
    position: relative;
    right: 73%;
    font-size: 100%;
  } 

  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: white;
}
  


  #contactov{
    padding: 21% 117% 0% 0%;
    font-size: 55%;
  }

}